import { Injectable } from '@angular/core';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { ToastController } from '@ionic/angular';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BluetoothService {
    private readonly PREFIX = 'Ident-Ex';
    private readonly TIMEOUT = 100; // milliseconds

    private scanDataSubject = new Subject<string>();
    public scanData$ = this.scanDataSubject.asObservable();

    private isConnected = false;
    private buffer = '';
    private bufferTimeout: any;

    constructor(private bluetoothSerial: BluetoothSerial, private messageService: MessageService, private toastController: ToastController) { }

    async connectToScanner(): Promise<void> {
        if (this.isConnected) {
            return;
        }

        try {
            const device = await this.findScanner();
            this.presentToast(`Connexion au scanner '${device.name}'...`);
            await this.connectToDevice(device);
        } catch (error) {
            console.error('Erreur de connexion Bluetooth', error);
            this.isConnected = false;
        }
    }

    private async findScanner() {
        const devices = await this.bluetoothSerial.list();
        const device = devices.find((d: any) => d.name.startsWith(this.PREFIX));

        if (!device) {
            throw new Error('Scanner non trouvé');
        }

        return device;
    }

    private async connectToDevice(device: any): Promise<void> {
        return new Promise((resolve, reject) => {
            this.bluetoothSerial.connect(device.address).subscribe(
                () => {
                    this.isConnected = true;
                    this.presentToast(`Connecté au scanner '${device.name}'`);
                    this.setupDataListener();
                    resolve();
                },
                (error) => {
                    this.isConnected = false;
                    this.presentToast(`Erreur connexion : ${error}`);
                    reject(error);
                }
            );
        });
    }

    private setupDataListener(): void {
        this.bluetoothSerial.subscribeRawData().subscribe(
            (data: ArrayBuffer) => this.handleScanData(data),
            (error) => this.presentToast(`Erreur réception données : ${error}`)
        );
    }

    private handleScanData(data: ArrayBuffer): void {
        const dataString = new TextDecoder().decode(data);
        this.buffer += dataString;

        if (this.bufferTimeout) {
            clearTimeout(this.bufferTimeout);
        }

        this.bufferTimeout = setTimeout(() => {
            if (this.buffer) {
                const completeBarcode = this.buffer.trim();
                this.presentToast(`Code-barre scanné : ${completeBarcode}`);

                this.scanDataSubject.next(completeBarcode);
                this.buffer = '';
            }
        }, this.TIMEOUT);
    }

    public async disconnect(): Promise<void> {
        if (this.isConnected) {
            await this.bluetoothSerial.disconnect();
            this.isConnected = false;
            this.buffer = '';
        }
    }

    public isDeviceConnected(): boolean {
        return this.isConnected;
    }

    public presentToast(message: string, duration: number = 2000): void {
        this.toastController.create({
            message,
            duration,
            position: 'bottom',
            cssClass: 'text-lg'
        }).then(toast => toast.present());
    }
}
