import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var BluetoothSerial = /** @class */function (_super) {
  __extends(BluetoothSerial, _super);
  function BluetoothSerial() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  BluetoothSerial.prototype.connect = function (macAddress_or_uuid) {
    return cordova(this, "connect", {
      "platforms": ["Android", "iOS", "Windows Phone"],
      "observable": true,
      "clearFunction": "disconnect"
    }, arguments);
  };
  BluetoothSerial.prototype.connectInsecure = function (macAddress) {
    return cordova(this, "connectInsecure", {
      "platforms": ["Android"],
      "observable": true,
      "clearFunction": "disconnect"
    }, arguments);
  };
  BluetoothSerial.prototype.disconnect = function () {
    return cordova(this, "disconnect", {}, arguments);
  };
  BluetoothSerial.prototype.write = function (data) {
    return cordova(this, "write", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.available = function () {
    return cordova(this, "available", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.read = function () {
    return cordova(this, "read", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.readUntil = function (delimiter) {
    return cordova(this, "readUntil", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.subscribe = function (delimiter) {
    return cordova(this, "subscribe", {
      "platforms": ["Android", "iOS", "Windows Phone"],
      "observable": true,
      "clearFunction": "unsubscribe"
    }, arguments);
  };
  BluetoothSerial.prototype.subscribeRawData = function () {
    return cordova(this, "subscribeRawData", {
      "platforms": ["Android", "iOS", "Windows Phone"],
      "observable": true,
      "clearFunction": "unsubscribeRawData"
    }, arguments);
  };
  BluetoothSerial.prototype.clear = function () {
    return cordova(this, "clear", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.list = function () {
    return cordova(this, "list", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.isEnabled = function () {
    return cordova(this, "isEnabled", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.isConnected = function () {
    return cordova(this, "isConnected", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.readRSSI = function () {
    return cordova(this, "readRSSI", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.showBluetoothSettings = function () {
    return cordova(this, "showBluetoothSettings", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.enable = function () {
    return cordova(this, "enable", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.discoverUnpaired = function () {
    return cordova(this, "discoverUnpaired", {
      "platforms": ["Android", "iOS", "Windows Phone"]
    }, arguments);
  };
  BluetoothSerial.prototype.setDeviceDiscoveredListener = function () {
    return cordova(this, "setDeviceDiscoveredListener", {
      "platforms": ["Android", "iOS", "Windows Phone"],
      "observable": true,
      "clearFunction": "clearDeviceDiscoveredListener"
    }, arguments);
  };
  BluetoothSerial.prototype.setName = function (newName) {
    return cordova(this, "setName", {
      "platforms": ["Android"],
      "sync": true
    }, arguments);
  };
  BluetoothSerial.prototype.setDiscoverable = function (discoverableDuration) {
    return cordova(this, "setDiscoverable", {
      "platforms": ["Android"],
      "sync": true
    }, arguments);
  };
  BluetoothSerial.ɵfac = /* @__PURE__ */(() => {
    let ɵBluetoothSerial_BaseFactory;
    return function BluetoothSerial_Factory(__ngFactoryType__) {
      return (ɵBluetoothSerial_BaseFactory || (ɵBluetoothSerial_BaseFactory = i0.ɵɵgetInheritedFactory(BluetoothSerial)))(__ngFactoryType__ || BluetoothSerial);
    };
  })();
  BluetoothSerial.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BluetoothSerial,
    factory: BluetoothSerial.ɵfac
  });
  BluetoothSerial.pluginName = "BluetoothSerial";
  BluetoothSerial.repo = "https://github.com/don/BluetoothSerial";
  BluetoothSerial.plugin = "cordova-plugin-bluetooth-serial";
  BluetoothSerial.pluginRef = "bluetoothSerial";
  BluetoothSerial.platforms = ["Android", "iOS", "Windows Phone 8"];
  BluetoothSerial = __decorate([], BluetoothSerial);
  return BluetoothSerial;
}(AwesomeCordovaNativePlugin);
export { BluetoothSerial };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && void 0;
})();
